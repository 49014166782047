import React, { useState } from 'react';

import { Colors } from '../../constants';
import db from '../../firebase.config';
import { IArea } from '../../interfaces';

interface IAreaOrder {
    id: string;
    order: number;
    name: string;
}

const areaRef = db.collection('areas');

const ReorderAreasModal = ({
    gymId,
    areas,
    handleCloseModal,
} : {
    gymId: string | undefined;
    areas: IArea[];
    handleCloseModal: () => void;
}) => {
    const [reorderedAreas, setReorderedAreas] = useState<IAreaOrder[]>(areas.map(area => ({id: area.id || '', order: area.order, name: area.name})));
    const [selectedAreaIndex, setSelectedAreaIndex] = useState<number | undefined>(undefined);

    const handleUpdateOrder = async () => {
        const batch = db.batch();
        for (const updatedArea of reorderedAreas) {
            batch.update(
                areaRef
                    .doc(updatedArea.id),
                {
                    order: updatedArea.order,
                }
            );
        };
        try {
            await batch.commit();
            handleCloseModal();
        } catch(err) {
            window.alert('Error updating order');
            console.log(err);
        }
    }

    const handleSwapAreas = (aIndex: number, bIndex: number) => {
        const aOrder = reorderedAreas[aIndex].order;
        reorderedAreas[aIndex].order = reorderedAreas[bIndex].order;
        reorderedAreas[bIndex].order = aOrder;
        setReorderedAreas(reorderedAreas);
        setSelectedAreaIndex(undefined);
    }

    return (
        <div style={{zIndex: 1, position: 'absolute', top: 0, height: '120vh', width: '100vw', backgroundColor: Colors.MODALBACKGROUND}}>
            <div style={{ backgroundColor: Colors.WHITE, borderRadius: 25, margin: 40, padding: 40, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h2>Reorder Areas</h2>
                <div style={{marginTop: 10}}>
                    {reorderedAreas.sort((a, b) => a.order - b.order).map((area, i) => {
                        const handleButtonClick = () => {
                            if (selectedAreaIndex === i) {
                                setSelectedAreaIndex(undefined);
                            } else if (selectedAreaIndex !== undefined) {
                                handleSwapAreas(i, selectedAreaIndex);
                            } else {
                                setSelectedAreaIndex(i);
                            }
                        }

                        return (
                            <div key={i} style={{display: 'flex', marginBottom: 10}}>
                                <p style={{fontSize: 20, minWidth: 200, margin: 0}}>{area.name}</p>
                                <button
                                    style={{}}
                                    onClick={handleButtonClick}
                                >
                                    {selectedAreaIndex !== undefined ? selectedAreaIndex !== i ? 'Swap' : 'Unselect' : 'Select'}
                                </button>
                            </div>
                        )
                    })}
                </div>
                <div style={{alignSelf: 'center', marginTop: 15}}>
                    <button
                        onClick={handleCloseModal}
                        style={{backgroundColor: Colors.WHITE, marginRight: 15}}
                    >
                        Close
                    </button>
                    <button
                        onClick={handleUpdateOrder}
                        style={{backgroundColor: Colors.MUSTARDSHADE}}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ReorderAreasModal;
