import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    nameKey: Yup.string()
        .required('Required'),
    timezone: Yup.string()
        .required('Required'),
    hours: Yup.array(
        Yup.string().required('required')
    ),
    primaryPhoneNumber: Yup.string()
        .required('Required'),
    primaryEmailAddress: Yup.string()
        .required('Required'),
    imageUri: Yup.string()
        .required('Required'),
    address: Yup.object().shape({
            address: Yup.string()
                .required('Required'),
            city: Yup.string()
                .required('Required'),
            state: Yup.string()
                .required('Required'),
            zipcode: Yup.string()
                .required('Required'),
        }),
});
