import * as Yup from 'yup';

export default Yup.object().shape({
    viewBoxAdjusted: Yup.string()
        .required('Required'),
    name: Yup.string()
        .required('Required'),
    label: Yup.array(
            Yup.object().shape({
                text: Yup.string()
                    .required('Required'),
                x: Yup.number()
                    .required('Required'),
                y: Yup.number()
                    .required('Required'),
            })
        ),
    paths: Yup.array(
            Yup.object().shape({
                path: Yup.string()
                    .required('Required'),
                strokeDash: Yup.string(),
                color: Yup.string()
                    .required('Required'),
            })
        ),
    transformAdjusted: Yup.object().shape({
            rotation: Yup.number()
                .required('Required'),
            scale: Yup.number()
                .required('Required'),
            translateX: Yup.number()
                .required('Required'),
            translateY: Yup.number()
                .required('Required'),
        })
});
