import React, { useState, useEffect } from 'react';

import db from '../../firebase.config';
import Header from '../dashboard/Header';

const gymsRef = db.collection('gyms');

interface IGymOption {
    id: string;
    name: string;
}

const PostNews = () => {
    const [gymSearch, setGymSearch] = useState<string>('');
    const [gyms, setGyms] = useState<any>(undefined);
    const [selectedGyms, setSelectedGyms] = useState<IGymOption[]>([]);
    const [message, setMessage] = useState<string>('');

    const handleGymSearch = () => {
        if (gymSearch === '') {
            gymsRef
                .orderBy('primaryPhoneNumber', 'desc')
                .limit(5)
                .get()
                .then(gyms => setGyms(gyms.docs))
        } else {
            let newGymSearch = ''
            gymSearch.split(' ').forEach((word, i) => {
                if (i !== 0) {
                    newGymSearch += ' ';
                }
                newGymSearch += word.charAt(0).toUpperCase() + word.slice(1);
            });
            gymsRef
                .where('name', '>=', newGymSearch)
                .where('name', '<=', newGymSearch + '\uf8ff')
                .limit(5)
                .get()
                .then(gymDocs => setGyms(gymDocs.docs));
        }
    }

    useEffect(() => {
        gymsRef
            .orderBy('primaryPhoneNumber', 'desc')
            .limit(5)
            .get()
            .then(gyms => setGyms(gyms.docs))
    }, []);

    const handleChangeGymSearch = (e: any) => setGymSearch(e.target.value);

    const handleAddGym = (e: any) => {
        const addedGym = gyms?.find((gym: any) => gym.id === e.target.value);
        if (selectedGyms.findIndex(gym => gym.id === addedGym.id) === -1) {
            setSelectedGyms([...selectedGyms, {id: addedGym.id, name: addedGym.data().name}]);
        }
    }
    const handleRemoveGym = (removeGym: IGymOption) => setSelectedGyms(selectedGyms.filter(gym => gym.id !== removeGym.id));

    const handleBack = () => window.location.href = '/';

    const handleChangeMessage = (e: any) => setMessage(e.target.value);

    const handlePostNews = async () => {
        if (selectedGyms.length === 0) {
            window.alert('you must select at least one gym');
            return;
        }

        for (const gym of selectedGyms) {
            await gymsRef
                .doc(gym.id)
                .collection('news')
                .add({
                    deletedAt: null,
                    imageUri: null,
                    likes: [],
                    message: message,
                    postDate: new Date(),
                });

            setSelectedGyms([]);
            setMessage('');
            window.alert('News posted successfully.');
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100vh'}}>
            <Header />

            <div style={{padding: 20, display: 'flex', flexDirection: 'row'}}>
                <button onClick={handleBack}>
                    &#60;- Back
                </button>
                <h3 style={{fontSize: 24, margin: 0, marginLeft: 10}}>
                    Post News
                </h3>
            </div>

            <div style={{padding: 20, display: 'flex', flexDirection: 'column', flex: 1, height: '100vh'}}>
                <h5 style={{marginBottom: 8}}>Gyms:</h5>
                <div>
                    <input type="text" id="gsearch" placeholder='Gym Search' value={gymSearch} onChange={handleChangeGymSearch} />
                    <button onClick={handleGymSearch} style={{marginLeft: 10}}>Search</button>
                </div>
                {!gyms ? <p>Loading...</p> :
                    <select value='' onChange={handleAddGym} style={{width: '40vw', minWidth: 200, height: '50px', marginTop: 10}}  >
                        <option value=''>Add a gym</option>
                        {gyms.map((gym: any) =>
                            <option key={gym.id} value={gym.id}>
                                {gym.data().name}
                            </option>
                        )}
                    </select>
                }

                <div>
                    {selectedGyms.map(gym => 
                        <button style={{marginTop: 10, marginRight: 10, borderRadius: 10, border: '1px solid grey'}} key={gym.id} onClick={() => handleRemoveGym(gym)}>
                            <p style={{margin: 0, padding: 5}}>{gym.name}  &#10006;</p>
                        </button>
                    )}
                </div>

                <textarea
                    id='message'
                    name='message'
                    placeholder='Message'
                    value={message}
                    onChange={handleChangeMessage}
                    style={{marginTop: 20, width: '75vw', minWidth: 300, height: 200}}
                />

                <button onClick={handlePostNews} style={{borderRadius: 5, width: 150, marginTop: 20}}>
                    Post News
                </button>
            </div>
        </div>
    );
}

export default PostNews;
