import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import Dashboard from './dashboard/Dashboard';
import LoginPage from './login/LoginPage';
import PostNews from './postNews/PostNews';
import { AuthProvider } from '../contexts/AuthContext';
import PrivateRoute from './login/PrivateRoute';

const App = () => {

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100vh'}}>
      <Router>
        <AuthProvider>
          <Switch>
            <Route
              path='/login'
              component={LoginPage}
            />
            <PrivateRoute
              exact
              path='/'
              component={Dashboard}
            />
            <PrivateRoute
              exact
              path='/post-news'
              component={PostNews}
            />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
