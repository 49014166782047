import * as Yup from 'yup';

export default Yup.object().shape({
    isColorGradeScale: Yup.boolean().required(),
    boulderGradeScale: Yup.array(
            Yup.object().shape({
                label: Yup.string().required('required'),
                value: Yup.mixed().required('required'),
                color: Yup.string(),
                rank: Yup.number().required('required'),
                key: Yup.string().required('required'),
                group: Yup.string().required('required'),
            })
        )
        .required('Required'),
    routeGradeScale: Yup.array(
            Yup.object().shape({
                label: Yup.string().required('required'),
                value: Yup.mixed().required('required'),
                rank: Yup.number().required('required'),
                key: Yup.string().required('required'),
                group: Yup.string().required('required'),
            })
        )
        .required('Required'),
});
