import React from 'react';
import { Formik, Field, FieldArray } from 'formik';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { gymGradeScaleSchema } from '../../validationSchema';
import Spinner from 'react-bootstrap/Spinner';
import { IGymSettingData } from '../../interfaces';

const gymsRef = db.collection('gyms');

const GymScaleFormModal = ({
    gymId,
    gymSettingData,
    handleCloseModal,
    setGymSettingData,
} : {
    gymId: string | undefined;
    gymSettingData: IGymSettingData | undefined;
    handleCloseModal: () => void;
    setGymSettingData: (gymSettingData: IGymSettingData) => void;
}) => {
    if (gymId === undefined) return null;

    const handleSaveGymGradeScale = async (updatedGymSettingData: IGymSettingData) => {
        const updatedBoulderGradeScale = updatedGymSettingData.boulderGradeScale.map(gradeOption => {
            if (gradeOption.value === 'null') {
                gradeOption.value = null;
            }
            return gradeOption;
        });
        const updatedRouteGradeScale = updatedGymSettingData.routeGradeScale.map(gradeOption => {
            if (gradeOption.value === 'null') {
                gradeOption.value = null;
            }
            return gradeOption;
        });

        await gymsRef
            .doc(gymId)
            .collection('climbSettingData')
            .doc(gymId)
            .update({
                isColorGradeScale: updatedGymSettingData.isColorGradeScale,
                boulderGradeScale: updatedBoulderGradeScale,
                routeGradeScale: updatedRouteGradeScale,
                isNamedClimbs: updatedGymSettingData.isNamedClimbs !== undefined ? updatedGymSettingData.isNamedClimbs : false,
                isSetterRequired: updatedGymSettingData.isSetterRequired,
                showGradeValue: updatedGymSettingData.showGradeValue,
                timezone: updatedGymSettingData.timezone || "America/New_York",
            });

        setGymSettingData(updatedGymSettingData);
        handleCloseModal();
    }

    const boulderGradeValueOptions = ['V0', 'V1','V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'V9', 'V10', 'null'];
    const routeGradeValueOptions = [
        '5.5', '5.6', '5.7', '5.8', '5.9', '5.10a', '5.10b', '5.10', '5.10c', '5.10d', '5.11a', '5.11b', '5.11',
        '5.11c','5.11d', '5.12a', '5.12b', '5.12', '5.12c', '5.12d', '5.13a', '5.13b', '5.13', '5.13c', '5.13d', 'null',
    ];

    gymSettingData?.boulderGradeScale.forEach(gradeOption => {
        if (gradeOption.value === null) {
            gradeOption.value = 'null';
        }
        return gradeOption;
    });
    gymSettingData?.routeGradeScale.forEach(gradeOption => {
        if (gradeOption.value === null) {
            gradeOption.value = 'null';
        }
        return gradeOption;
    });
    if (gymSettingData) {
        gymSettingData.isSetterRequired = (gymSettingData.isSetterRequired === undefined) ? true : gymSettingData.isSetterRequired;
    }

    return (
        <div style={{zIndex: 1, position: 'absolute', top: 0, minHeight: '100%', width: '100%', backgroundColor: Colors.MODALBACKGROUND}}>
            <div style={{ backgroundColor: Colors.WHITE, borderRadius: 25, margin: 40, padding: 40}}>
                {gymSettingData === undefined ? (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Spinner animation='border' style={{alignSelf: 'center'}} />
                        <button
                            type="button"
                            style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, padding: 5, marginTop: 25}}
                            onClick={handleCloseModal}
                        >
                            Close
                        </button>
                    </div>
                ) : (
                    <div>
                        <h2 style={{textAlign: 'center', margin: 0}}>Edit Gym Grade Scale</h2>
                        <Formik
                            initialValues={gymSettingData}
                            validationSchema={gymGradeScaleSchema}
                            onSubmit={handleSaveGymGradeScale}
                        >
                            {({ values, submitForm, setFieldValue }) => {
                                const handleIsColorGradeScaleToggle = () => {
                                    if (values.isColorGradeScale) {
                                        setFieldValue(
                                            'boulderGradeScale',
                                            values.boulderGradeScale.map(scale => ({
                                                label: scale.label,
                                                value: scale.value,
                                                color: '',
                                                key: scale.key,
                                                group: scale.group,
                                                rank: scale.rank,
                                            }))
                                        );
                                    }
                                    setFieldValue('isColorGradeScale', !values.isColorGradeScale);
                                }

                                return(
                                    <div style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column', padding: '10px 50px 0 50px'}}>
                                        <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                                            <Field type="checkbox" name="isSetterRequired" />
                                            <h5 style={{margin: '0 0 0 10px'}}>Setter Required?</h5>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                                            <Field type="checkbox" name="isNamedClimbs" />
                                            <h5 style={{margin: '0 0 0 10px'}}>Named Climbs?</h5>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                                            <Field type="checkbox" name="showGradeValue" />
                                            <h5 style={{margin: '0 0 0 10px'}}>Show Grade Value</h5>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                                            <h5 style={{margin: '0 10px 0 0'}}>Timezone</h5>
                                            <Field as="select" name="timezone">
                                                <option value="">Select Timezone</option>
                                                <option value="America/New_York">America/New_York (eastern)</option>
                                                <option value="America/Chicago">America/Chicago (central)</option>
                                                <option value="America/Denver">America/Denver (mountain)</option>
                                                <option value="America/Los_Angeles">America/Los_Angeles (pacific)</option>
                                            </Field>
                                        </div>
                                        <div style={{marginBottom: '23px', marginTop: '10px'}}>
                                            <FieldArray name="boulderGradeScale">
                                                {({ remove, push }: any) => (
                                                    <div style={{marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 15}}>
                                                        <h5>Boulder Scale</h5>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <p style={{margin: '0 10px 0 0'}}>Is Color Grade Scale:</p>
                                                            <Field type="checkbox" name="isColorGradeScale" onChange={handleIsColorGradeScaleToggle} />
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 5}}>
                                                            <p style={{marginBottom: 0, width: 135 }}>Label</p>
                                                            <p style={{marginBottom: 0, width: 160 }}>Value</p>
                                                            <p style={{marginBottom: 0, width: 135 }}>Group</p>
                                                            <p style={{marginBottom: 0, width: 135 }}>Key</p>
                                                            {values.isColorGradeScale && <p style={{marginBottom: 0, width: 110 }}>Color</p>}
                                                            <p style={{marginBottom: 0, width: 135 }}>Rank</p>
                                                        </div>
                                                        {values.boulderGradeScale.map((label: any, index: number) => (
                                                            <div style={{display: 'flex', flexDirection: 'row', marginTop: 5}} key={index}>
                                                                <Field
                                                                    style={{marginRight: 10, width: 125}}
                                                                    name={`boulderGradeScale.${index}.label`}
                                                                    placeholder="Label"
                                                                    type="text"
                                                                />
                                                                <Field
                                                                    style={{marginRight: 10, width: 150}}
                                                                    name={`boulderGradeScale.${index}.value`}
                                                                    placeholder="Value"
                                                                    as="select"
                                                                >
                                                                    {boulderGradeValueOptions.map(value =>
                                                                        <option key={value} value={value || 'null'}>{value || 'null'}</option>
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    style={{marginRight: 10, width: 125}}
                                                                    name={`boulderGradeScale.${index}.group`}
                                                                    placeholder="Group"
                                                                    type="text"
                                                                />
                                                                <Field
                                                                    style={{marginRight: 10, width: 125}}
                                                                    name={`boulderGradeScale.${index}.key`}
                                                                    placeholder="Key"
                                                                    type="text"
                                                                />
                                                                {values.isColorGradeScale && (
                                                                    <Field
                                                                        style={{marginRight: 10, width: 100}}
                                                                        name={`boulderGradeScale.${index}.color`}
                                                                        placeholder="Color"
                                                                        type="text"
                                                                    />
                                                                )}
                                                                <Field
                                                                    style={{marginRight: 10, width: 125}}
                                                                    name={`boulderGradeScale.${index}.rank`}
                                                                    placeholder="Rank"
                                                                    type="number"
                                                                    disabled
                                                                />

                                                                <button
                                                                    type="button"
                                                                    className="secondary"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    &#10005;
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <button
                                                            type="button"
                                                            style={{backgroundColor: Colors.MUSTARDSHADE, borderColor: Colors.LTGREY, margin: 15}}
                                                            onClick={() => push({label: '', value: 'V0', color: '', group: '', key: '', rank: values.boulderGradeScale.length})}
                                                        >
                                                            Add Scale
                                                        </button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                            <FieldArray name="routeGradeScale">
                                                {({ remove, push }: any) => (
                                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                        <h5>Route Scale</h5>
                                                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 5, marginRight: 55}}>
                                                            <p style={{marginBottom: 0, width: 190 }}>Label</p>
                                                            <p style={{marginBottom: 0, width: 160 }}>Value</p>
                                                            <p style={{marginBottom: 0, width: 195 }}>Group</p>
                                                            <p style={{marginBottom: 0, width: 195 }}>Key</p>
                                                            <p style={{marginBottom: 0, width: 195 }}>Rank</p>
                                                        </div>
                                                        {values.routeGradeScale.map((label: any, index: number) => (
                                                            <div style={{display: 'flex', flexDirection: 'row', marginTop: 5}} key={index}>
                                                                <Field
                                                                    style={{marginRight: 10}}
                                                                    name={`routeGradeScale.${index}.label`}
                                                                    placeholder="Label"
                                                                    type="text"
                                                                />
                                                                <Field
                                                                    style={{marginRight: 10, minWidth: 150}}
                                                                    name={`routeGradeScale.${index}.value`}
                                                                    placeholder="Value"
                                                                    as="select"
                                                                >
                                                                    {routeGradeValueOptions.map(value =>
                                                                        <option key={value} value={value || 'null'}>{value || 'null'}</option>
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    style={{marginRight: 10}}
                                                                    name={`routeGradeScale.${index}.group`}
                                                                    placeholder="Group"
                                                                    type="text"
                                                                />
                                                                <Field
                                                                    style={{marginRight: 10}}
                                                                    name={`routeGradeScale.${index}.key`}
                                                                    placeholder="Key"
                                                                    type="text"
                                                                />
                                                                <Field
                                                                    style={{marginRight: 10}}
                                                                    name={`routeGradeScale.${index}.rank`}
                                                                    placeholder="Rank"
                                                                    type="number"
                                                                    disabled
                                                                />

                                                                <div className="col">
                                                                    <button
                                                                        type="button"
                                                                        className="secondary"
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        &#10005;
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button
                                                            type="button"
                                                            style={{backgroundColor: Colors.MUSTARDSHADE, borderColor: Colors.LTGREY, margin: 15}}
                                                            onClick={() => push({label: '', value: 'V0', group: '', key: '', rank: values.routeGradeScale.length})}
                                                        >
                                                            Add Scale
                                                        </button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>

                                        <div style={{alignSelf: 'center'}}>
                                            <button
                                                type="button"
                                                style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, marginRight: 15, padding: 5}}
                                                onClick={handleCloseModal}
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="submit"
                                                style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, padding: 5}}
                                                onClick={submitForm}
                                            >
                                                Save
                                            </button>
                                        </div>
                                        
                                    </div>
                                )
                            }}
                        </Formik>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GymScaleFormModal;
