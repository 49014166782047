import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Navbar from 'react-bootstrap/Navbar';

import { useAuth } from '../../contexts/AuthContext';

const LoginPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const emailRef = useRef<any>();
    const passwordRef = useRef<any>();
    const { login } = useAuth();
    const history = useHistory();

    const handleLogin = async (e: any) => {
        e.preventDefault();

        try {
            setError(undefined);
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
            setTimeout(() => history.push('/'), 1000);
        } catch (err) {
            setError('Failed to log in!');
            setLoading(false);
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column' }}>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand>Climb Time Admin</Navbar.Brand>
            </Navbar>

            <Card className='w-50 align-self-center' style={{maxWidth: '500px', marginTop: '50px'}}>
                <Card.Body>
                    <h2 className='text-center mb-4'>Log In</h2>
                    {error && <Alert variant='danger'>{error}</Alert>}
                    <Form onSubmit={handleLogin}>
                        <Form.Group id='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type='email' ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id='password'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='password' ref={passwordRef} required />
                        </Form.Group>
                        <Button
                            disabled={loading}
                            className='w-100'
                            type='submit'
                        >
                            Log In
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default LoginPage;
