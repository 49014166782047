import React from 'react';
import { Formik, Field, FieldArray } from 'formik';
 
import { Colors } from '../../constants';
import { IMarker } from '../../interfaces';
import { markerSchema } from '../../validationSchema';

const MarkerForm = ({
    marker,
    method,
    handleCloseModal,
    handleSaveMarker,
} : {
    marker: IMarker | undefined;
    method: string;
    handleCloseModal: (() => void) | undefined;
    handleSaveMarker: (marker: IMarker) => void;
}) => {

    const initialMarkerValues = marker || {
        id: '',
        name: '',
        path: '',
        color: '',
        label: [{
            text: '',
            x: 0, 
            y: 0,
            transform: '',
        }],
    } as IMarker;

    const isEdit = method === 'edit';

    const style = isEdit ? {
        backgroundColor: Colors.OFFWHITE,
        margin: 15,
        padding: 20,
    } : {
        backgroundColor: Colors.WHITE,
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, ...style}}>
            <Formik
                initialValues={initialMarkerValues}
                validationSchema={markerSchema}
                onSubmit={handleSaveMarker}
            >
                {({ values, submitForm }) => {

                    return(
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <label style={{marginTop: 20}} htmlFor="name">Name</label>
                            <Field id="name" name="name" placeholder="Marker Name" style={{height: 25}} />

                            <label style={{marginTop: 10}} htmlFor="path">Path</label>
                            <Field id="path" name="path" placeholder="Marker SVG Path" style={{height: 25}} />

                            <label style={{marginTop: 10}} htmlFor="color">Color</label>
                            <Field id="color" name="color" placeholder="Marker Color" style={{height: 25}} />

                            <label style={{marginTop: 10}} htmlFor="label">Labels</label>
                            <FieldArray name="label">
                                {({ remove, push }: any) => (
                                    <div>
                                        {values.label.map((label: any, index: number) => (
                                            <div style={{display: 'flex', flexDirection: 'row'}} key={index}>
                                                <div style={{marginLeft: 15, display: 'flex', flexDirection: 'row'}}>
                                                    <label htmlFor={`label.${index}.text`}>text:</label>
                                                    <Field
                                                        style={{marginLeft: 5, marginRight: 10, width: 100}}
                                                        name={`label.${index}.text`}
                                                        placeholder="text"
                                                        type="text"
                                                    />
                                                
                                                    <label htmlFor={`label.${index}.x`}>x:</label>
                                                    <Field
                                                        style={{marginLeft: 5, marginRight: 10, width: 70}}
                                                        name={`label.${index}.x`}
                                                        placeholder="x"
                                                        type="number"
                                                    />
                                                
                                                    <label htmlFor={`label.${index}.y`}>y:</label>
                                                    <Field
                                                        style={{marginLeft: 5, marginRight: 10, width: 70}}
                                                        name={`label.${index}.y`}
                                                        placeholder="y"
                                                        type="number"
                                                    />

                                                    <label htmlFor={`label.${index}.transform`}>transform:</label>
                                                    <Field
                                                        style={{marginLeft: 5, marginRight: 10}}
                                                        name={`label.${index}.transform`}
                                                        placeholder="transform"
                                                        type="string"
                                                    />
                                                </div>

                                                <div className="col">
                                                    <button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => remove(index)}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            style={{backgroundColor: Colors.MUSTARDSHADE, borderColor: Colors.LTGREY, margin: 15}}
                                            onClick={() => push({ text: '', x: 0, y: 0 })}
                                        >
                                            Add Label
                                        </button>
                                    </div>
                                )}
                            </FieldArray>

                            <div style={{alignSelf: 'center'}}>
                                {!isEdit &&
                                    <button
                                        type="button"
                                        style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, marginRight: 15, padding: 5}}
                                        onClick={handleCloseModal}
                                    >
                                        Close
                                    </button>
                                }
                                <button
                                    onClick={submitForm}
                                    type="submit"
                                    style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, padding: 5}}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
}

export default MarkerForm;
