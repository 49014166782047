import React, { useState } from 'react';
import { Formik, Field, FieldArray } from 'formik';
 
import { Colors } from '../../constants';
import { IPath, IWall } from '../../interfaces';
import { wallSchema } from '../../validationSchema';

const WallForm = ({
    wall,
    method,
    areaColor,
    handleCloseModal,
    handleSaveWall,
} : {
    wall: IWall | undefined;
    method: string;
    areaColor: string;
    handleCloseModal: (() => void) | undefined;
    handleSaveWall: (wall: IWall) => void;
}) => {
    const getWallPathSegments = (paths: IPath[]) => {
        const segments: any[] = [];

        for (let i = 0; i<paths.length; i++) {
            segments.push([]);
            const path = paths[i];
            const svgCommnads: string[] = [];
            let currentCommand = '';
            for (const char of path.path) {
                if (['M', 'L', 'V', 'H', 'Z'].includes(char)) {
                    if (currentCommand !== '') {
                        svgCommnads.push(currentCommand);
                        currentCommand = '';
                    }
                }
                currentCommand += char;
            }

            let dashStrokeCopy = path.strokeDash || '';
            for (let k = 0; k < svgCommnads.length - 1; k++) {
                const points1 = [parseFloat(svgCommnads[k].slice(1).split(' ')[0]), parseFloat(svgCommnads[k].slice(1).split(' ')[1])];
                const points2 = [parseFloat(svgCommnads[k + 1].slice(1).split(' ')[0]), parseFloat(svgCommnads[k + 1].slice(1).split(' ')[1])];
                let segmentDistance = Math.round(Math.sqrt(Math.pow(points1[0] - points2[0], 2) + Math.pow(points1[1] - points2[1], 2)));
                const solidStrokeDash = `${segmentDistance} 0 `;
                let dashedStrokeDash = '';
                while (segmentDistance > 0) {
                    if (segmentDistance < 16) {
                        const empty = segmentDistance - 8 > 0 ? segmentDistance - 8 : 0;
                        const dash = segmentDistance > 8 ? 8 : segmentDistance
                        dashedStrokeDash += `${dash} ${empty} `;
                    } else {
                        dashedStrokeDash += '8 8 ';
                    }
                    segmentDistance -= 16;
                }
                const segmentChecked = dashStrokeCopy.startsWith(solidStrokeDash);
                segments[i].push({
                    coordinates: [svgCommnads[k], svgCommnads[k+1]],
                    solidStrokeDash: solidStrokeDash,
                    dashedStrokeDash: dashedStrokeDash,
                    checked: segmentChecked,
                });
                dashStrokeCopy = dashStrokeCopy.slice(segmentChecked ? solidStrokeDash.length : dashedStrokeDash.length);
            }
        }
        return segments;
    }

    const [segments, setSegments] = useState<any[][]>(!wall ?
        getWallPathSegments([{
            path: '',
            strokeDash: '',
            color: areaColor,
        }]) :
        getWallPathSegments(wall.paths)
    );

    const initialWallValues = wall || {
        id: '',
        name: '',
        paths: [{
            path: '',
            strokeDash: '',
            color: areaColor,
        }],
        viewBoxAdjusted: '0 0 100 100',
        label: [{
            text: '',
            x: 0, 
            y: 0,
            transform: '',
        }],
        transformAdjusted: {
            rotation: 0,
            scale: 1,
            translateX: 0,
            translateY: 0,
        },
        routeRadius: 7,
        coordAdjust: [0, 0],
    } as IWall;

    const isEdit = method === 'edit';

    const getStrokeDashFromSegments = (index: number) => {
        let strokeDash = '';
        segments[index].forEach((segment: any) => {
            strokeDash += segment.checked ? segment.solidStrokeDash : segment.dashedStrokeDash;
        })
        return strokeDash;
    }

    const style = isEdit ? {
        backgroundColor: Colors.OFFWHITE,
        margin: 15,
        padding: 20,
    } : {
        backgroundColor: Colors.WHITE,
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, ...style}}>
            <Formik
                initialValues={initialWallValues}
                validationSchema={wallSchema}
                onSubmit={handleSaveWall}
            >
                {({ values, submitForm, setFieldValue }) => {                    
                    const handleSetPathSegments = () => setSegments(getWallPathSegments(values.paths));

                    const handleCheckboxChange = (pathIndex: number, segmentIndex: number) => {
                        const segmentsCopy = [...segments];
                        segmentsCopy[pathIndex][segmentIndex].checked = !segmentsCopy[pathIndex][segmentIndex].checked;
                        setSegments(segmentsCopy);
                        setFieldValue(`paths.${pathIndex}.strokeDash`, getStrokeDashFromSegments(pathIndex));
                    }

                    return(
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <label style={{marginTop: 20}} htmlFor="name">Name</label>
                            <Field id="name" name="name" placeholder="Wall Name" style={{height: 25}} />

                            <FieldArray name="paths">
                                {({ remove, push }: any) => {
                                    const handlePushPath = () => {
                                        push({ path: '', strokeDash: '', color: areaColor });
                                        segments.push([]);
                                        setSegments(segments);
                                    }

                                    return (
                                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                            <label style={{marginTop: 10, marginBottom: 0}} htmlFor="paths">Paths:</label>
                                            {values.paths.map((path, index) => {
                                                const handleRemovePath = () => {
                                                    remove(index);
                                                    segments.splice(index, 1)
                                                    setSegments(segments);
                                                }

                                                return (
                                                    <div style={{display: 'flex', flexDirection: 'row', margin: 5}} key={index}>
                                                        <div style={{backgroundColor: Colors.GREY, width: 4}} />
                                                        <div style={{marginLeft: 15, display: 'flex', flexDirection: 'column', flex: 1}}>
                                                            <label htmlFor={`paths.${index}.path`}>Path:</label>
                                                            <Field
                                                                style={{marginLeft: 5, marginRight: 10}}
                                                                name={`paths.${index}.path`}
                                                                placeholder="Path"
                                                                type="text"
                                                            />

                                                            <label htmlFor={`paths.${index}.strokeDash`}>Stroke Dash:</label>
                                                            {segments[index].map((segment: any, i: number) => {
                                                                const handleCheckboxChangeClick = () => handleCheckboxChange(index, i);
                                                                return (
                                                                    <div style={{display: 'flex', alignItems: 'center'}} key={i}>
                                                                        <input type="checkbox" checked={segment.checked} id={i+''} value={i} onChange={handleCheckboxChangeClick} />
                                                                        <p style={{margin: 0, marginLeft: '10px'}}>{segment.coordinates[0] + ' - ' + segment.coordinates[1]}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                            <Field
                                                                style={{marginLeft: 5, marginRight: 10}}
                                                                name={`paths.${index}.strokeDash`}
                                                                placeholder="Stroke Dash (leave empty if no dash)"
                                                                type="text"
                                                            />                                                    

                                                            <label htmlFor={`paths.${index}.color`}>Color:</label>
                                                            <Field
                                                                style={{marginLeft: 5, marginRight: 10}}
                                                                name={`paths.${index}.color`}
                                                                placeholder="Color"
                                                                type="text"
                                                            />
                                                        </div>

                                                        <button
                                                            type="button"
                                                            style={{alignSelf: 'flex-start'}}
                                                            onClick={handleRemovePath}
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                            <div>
                                                <button
                                                    type="button"
                                                    style={{backgroundColor: Colors.MUSTARDSHADE, borderColor: Colors.LTGREY, margin: 15}}
                                                    onClick={handlePushPath}
                                                >
                                                    Add Path
                                                </button>
                                                <button
                                                    type="button"
                                                    style={{borderColor: Colors.LTLTGREY, margin: 5, borderRadius: 10}}
                                                    onClick={handleSetPathSegments}
                                                >
                                                    Calculate Segments
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }}
                            </FieldArray>

                            <label style={{marginTop: 10, marginBottom: 3}} htmlFor="viewBoxAdjusted">ViewBox</label>
                            <Field id="viewBoxAdjusted" name="viewBoxAdjusted" placeholder="View Box" style={{height: 25}} />

                            <label style={{marginTop: 10, marginBottom: 3}} htmlFor="transformAdjusted">Transform</label>
                            <div style={{marginLeft: 20}}>
                                <div>
                                    <label style={{marginRight: 8}} htmlFor="transformAdjusted">Rotation:</label>
                                    <Field type="number" id="transformAdjusted.rotation" name="transformAdjusted.rotation" placeholder="Rotation" style={{height: 25}} />
                                </div>
                                <div>
                                    <label style={{marginRight: 8}} htmlFor="transform">Scale:</label>
                                    <Field type="number" id="transformAdjusted.scale" name="transformAdjusted.scale" placeholder="Scale" style={{height: 25}} />
                                </div>
                                <div>
                                    <label style={{marginRight: 8}} htmlFor="transform">TranslateX:</label>
                                    <Field type="number" id="transformAdjusted.translateX" name="transformAdjusted.translateX" placeholder="TranslateX" style={{height: 25}} />
                                </div>
                                <div>
                                    <label style={{marginRight: 8}} htmlFor="transform">TranslateY:</label>
                                    <Field type="number" id="transformAdjusted.translateY" name="transformAdjusted.translateY" placeholder="TranslateY" style={{height: 25}} />
                                </div>
                            </div>

                            <label style={{marginTop: 10, marginBottom: 3}} htmlFor='routeRadius'>Route Radius:</label>
                            <Field
                                name='routeRadius'
                                placeholder="Route Marker Radius"
                                type="number"
                            />

                            <label style={{marginTop: 10, marginBottom: 3}} htmlFor="label">Labels</label>
                            <FieldArray name="label">
                                {({ remove, push }: any) => (
                                    <div>
                                        {values.label.map((label: any, index: number) => (
                                            <div style={{display: 'flex', flexDirection: 'row'}} key={index}>
                                                <div style={{marginLeft: 15, display: 'flex', flexDirection: 'row'}}>
                                                    <label htmlFor={`label.${index}.text`}>text:</label>
                                                    <Field
                                                        style={{marginLeft: 5, marginRight: 10, width: 100}}
                                                        name={`label.${index}.text`}
                                                        placeholder="text"
                                                        type="text"
                                                    />
                                                
                                                    <label htmlFor={`label.${index}.x`}>x:</label>
                                                    <Field
                                                        style={{marginLeft: 5, marginRight: 10, width: 70}}
                                                        name={`label.${index}.x`}
                                                        placeholder="x"
                                                        type="number"
                                                    />
                                                
                                                    <label htmlFor={`label.${index}.y`}>y:</label>
                                                    <Field
                                                        style={{marginLeft: 5, marginRight: 10, width: 70}}
                                                        name={`label.${index}.y`}
                                                        placeholder="y"
                                                        type="number"
                                                    />

                                                    <label htmlFor={`label.${index}.transform`}>transform:</label>
                                                    <Field
                                                        style={{marginLeft: 5, marginRight: 10}}
                                                        name={`label.${index}.transform`}
                                                        placeholder="transform"
                                                        type="string"
                                                    />
                                                </div>

                                                <div className="col">
                                                    <button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => remove(index)}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            style={{backgroundColor: Colors.MUSTARDSHADE, borderColor: Colors.LTGREY, margin: 15}}
                                            onClick={() => push({ text: '', x: 0, y: 0 })}
                                        >
                                            Add Label
                                        </button>
                                    </div>
                                )}
                            </FieldArray>

                            <div style={{alignSelf: 'center'}}>
                                {!isEdit &&
                                    <button
                                        type="button"
                                        style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, marginRight: 15, padding: 5}}
                                        onClick={handleCloseModal}
                                    >
                                        Close
                                    </button>
                                }
                                <button
                                    onClick={submitForm}
                                    type="submit"
                                    style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, padding: 5}}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
}

export default WallForm;
