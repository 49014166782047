export const removeBadSvgPathVars = (path: string): string => {
    const svgCommnads: string[] = [];
    let currentCommand = '';
    for (const char of path) {
        if (['M', 'L', 'V', 'H', 'Z'].includes(char)) {
            if (currentCommand !== '') {
                svgCommnads.push(currentCommand);
                currentCommand = '';
            }
        }
        currentCommand += char;
    }
    svgCommnads.push(currentCommand);

    let correctedPath = '';
    for (let i = 0; i<svgCommnads.length; i++) {
        const command = svgCommnads[i];
        if (command[0] === 'V') {
            const previousCoordinates = svgCommnads[i - 1].slice(1).split(' ');
            const correctedCommand = 'L' + previousCoordinates[0] + ' ' + command.slice(1);
            svgCommnads[i] = correctedCommand;
            correctedPath += correctedCommand;
        } else if (command[0] === 'H') {
            const previousCoordinates = svgCommnads[i - 1].slice(1).split(' ');
            const correctedCommand = 'L' + command.slice(1) + ' ' + previousCoordinates[1];
            svgCommnads[i] = correctedCommand;
            correctedPath += correctedCommand;
        } else {
            correctedPath += command;
        }
    };
    return correctedPath;
}