import * as Yup from 'yup';

export default Yup.object().shape({
    color: Yup.string()
        .required('Required'),
    gymId: Yup.string()
        .required('Required'),
    lastReset: Yup.date()
        .required('Required'),
    name: Yup.string()
        .required('Required'),
    climbType: Yup.string()
        .required('Required'),
});
