import React, { useEffect, useState } from 'react';

import { Colors } from '../../constants';
import { IArea, IWall } from '../../interfaces';

const wallWidth = 351;
const wallHeight = 260.2031;

const WallMap = ({
    area,
    wall,
} : {
    area: IArea,
    wall: IWall,
}) => {
    const [transform, setTransform] = useState<any>(wall.transformAdjusted);

    const viewBoxPoints = (wall.viewBoxAdjusted || '').split(' ');
    const viewBoxStartX = parseInt(viewBoxPoints[0]);
    const viewBoxStartY = parseInt(viewBoxPoints[1]);
    const viewBoxWidth = parseInt(viewBoxPoints[2]);
    const viewBoxHeight = parseInt(viewBoxPoints[3]);
    const viewBoxCenterX = viewBoxStartX + viewBoxWidth/2;
    const viewBoxCenterY = viewBoxStartY + viewBoxHeight/2;

    useEffect(() => {
        setTransform(wall.transformAdjusted);
    }, [wall.id, viewBoxStartX, viewBoxStartY]);

    const handleChangeTransform = (e: any) => {
        const transformCopy = {...transform};
        transformCopy[e.target.name] = e.target.value ? parseFloat(e.target.value) : '';
        setTransform(transformCopy);
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <svg height={wallHeight} width={wallWidth} viewBox={wall.viewBoxAdjusted} style={{alignSelf: 'center', backgroundColor: Colors.OFFWHITE}}>
                <g
                    transform={`
                        translate(${transform.translateX || 0}, ${transform.translateY || 0})
                        rotate(${transform.rotation || 0} ${viewBoxCenterX} ${viewBoxCenterY})
                        scale(${transform.scale || 1})
                    `}
                >
                    {wall.paths.map((path, i) => 
                        <path
                            key={i}
                            d={path.path}
                            stroke={Colors.BLACK}
                            strokeWidth={1.5}
                            strokeDasharray={path.strokeDash}
                            fill={path.color || area.color}
                        />
                    )}
                </g>

                <circle
                    cx={viewBoxCenterX / transform.scale}
                    cy={viewBoxCenterY / transform.scale}
                    fill="white"
                    r={wall.routeRadius}
                    stroke="black"
                    transform={`
                        scale(${transform.scale || 1})
                    `}
                />
            </svg>

            <label style={{marginTop: 10, marginBottom: 3}} htmlFor="transformAdjusted">Transform</label>
            <div style={{marginLeft: 20}}>
                <div>
                    <label style={{marginRight: 8}} htmlFor="transformAdjusted">Rotation:</label>
                    <input type="number" name='rotation' id="transformAdjusted.rotation" value={transform.rotation} onChange={handleChangeTransform} placeholder="Rotation" style={{height: 25}} />
                </div>
                <div>
                    <label style={{marginRight: 8}} htmlFor="transform">Scale:</label>
                    <input type="number" name='scale' id="transformAdjusted.scale" value={transform.scale} onChange={handleChangeTransform} placeholder="Scale" style={{height: 25}} />
                </div>
                <div>
                    <label style={{marginRight: 8}} htmlFor="transform">TranslateX:</label>
                    <input type="number" name='translateX' id="transformAdjusted.translateX" value={transform.translateX} onChange={handleChangeTransform} placeholder="TranslateX" style={{height: 25}} />
                </div>
                <div>
                    <label style={{marginRight: 8}} htmlFor="transform">TranslateY:</label>
                    <input type="number" name='translateY' id="transformAdjusted.translateY" value={transform.translateY} onChange={handleChangeTransform} placeholder="TranslateY" style={{height: 25}} />
                </div>
            </div>
        </div>
    );
}

export default WallMap;
