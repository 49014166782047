import React from 'react';
import { Colors } from '../../constants';
import { IArea, IMarker, IWall } from '../../interfaces';

const areaWidth = window.innerHeight * 0.6;

const AreaMap = ({
    area,
} : {
    area: IArea,
}) => {
  return (
    <div>
        <svg height={areaWidth * 427/576} width={areaWidth} viewBox="0 0 576 427" style={{alignSelf: 'center'}}>
            {area.walls.map((wall: IWall) => 
                <g
                    key={wall.id}
                    fill={area.color}
                    stroke={Colors.BLACK}
                    strokeWidth={1.5}
                >
                    {wall.paths.map((path, i) => 
                        <path key={i} d={path.path} strokeDasharray={path.strokeDash} fill={path.color || area.color} />
                    )}
                    {wall.label.map((label: any, i: number) => 
                        <text key={i} transform={label.transform} fontFamily='Montserrat-ExtraBold' fontSize={14} x={label.x} y={label.y}>
                            {label.text}
                        </text>
                    )}
                </g>
            )}
            {area.markers.map((marker: IMarker) => 
                <g
                    key={marker.id}
                    fill={marker.color}
                    stroke={Colors.BLACK}
                    strokeWidth={1.5}
                >
                    <path id={marker.id} d={marker.path} />
                    {marker.label.map((label: any, i: number) => 
                        <text key={i} transform={label.transform} fontFamily='Montserrat-ExtraBold' fontSize={14} x={label.x} y={label.y}>
                            {label.text}
                        </text>
                    )}
                </g>
            )}
        </svg>
    </div>
  );
}

export default AreaMap;
