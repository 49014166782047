import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PrivateRoute = ({
    component: Component,
    ...rest
} : {
    component: any;
    [index: string]: any;
}) => {
    const { currentUser } = useAuth();

    return (
        <Route
            {...rest}
            render={props => 
                currentUser ? <Component {...props} /> : <Redirect to='/login' />
            }
        />
    );
}

export default PrivateRoute;
