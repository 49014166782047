import React, { createContext, useContext, useState, useEffect } from 'react';
import db, { auth } from '../firebase.config';

type ContextProps = {
    currentUser: any,
    logout: any;
    login: any;
};

const adminsRef = db.collection('admin');

const AuthContext = createContext<Partial<ContextProps>>({});

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({
    children,
} : {
    children: any;
}) => {
    const [currentUser, setCurrentUser] = useState<any>(localStorage.getItem('userData'));
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            try {
                if (user) {
                    const adminDoc = await adminsRef
                        .doc(user.uid)
                        .get();

                    if (adminDoc && adminDoc?.exists) {
                        setCurrentUser(user);
                        localStorage.setItem('userData', JSON.stringify(user));
                    } else {
                        auth.signOut();
                        window.alert('You are not an admin!');
                    }
                } else {
                    localStorage.clear();
                    setCurrentUser(null);
                }
            } catch (err) {
                auth.signOut();
            }

            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const login = (email: string, password: string) => {
        return auth.signInWithEmailAndPassword(email, password);
    }

    const logout = () => {
        return auth.signOut();
    }

    const value = {
        currentUser,
        login,
        logout,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}