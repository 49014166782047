import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    label: Yup.array(
            Yup.object().shape({
                text: Yup.string()
                    .required('Required'),
                x: Yup.number()
                    .required('Required'),
                y: Yup.number()
                    .required('Required'),
            })
        ),
    path: Yup.string()
        .required('Required'),
    color: Yup.string()
        .required('Required'),
});
