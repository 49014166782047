import React, { useState, useEffect } from 'react';
import cuid from 'cuid';

import { Colors } from '../../constants';
import { IGym, IArea, IWall, IGymSettingData, IMarker } from '../../interfaces';
import AreaMap from '../areas/AreaMap';
import WallForm from '../walls/WallForm';
import AreaFormModal from '../areas/AreaFormModal';
import WallMap from '../walls/WallMap';

import db from '../../firebase.config';
import Header from './Header';
import ReorderAreasModal from '../areas/ReorderAreasModal';
import GymScaleFormModal from '../gyms/GymScaleFormModal';
import { removeBadSvgPathVars } from '../../utils/removeBadSvgPathVars';
import MarkerForm from '../markers/MarkerForm';
// import { handleBulkAction } from '../../utils/handleBulkAction';

const gymRef = db.collection('gyms');
const areaRef = db.collection('areas');
const gymMetadataRef = db.collection('gymMetadata');

const Dashboard = () => {
  const [gymSearch, setGymSearch] = useState<string>('');
  const [gyms, setGyms] = useState<any>(undefined);
  const [areas, setAreas] = useState<any>(undefined);

  const [selectedGym, setSelectedGym] = useState<IGym | undefined>(undefined);
  const [gymSettingData, setGymSettingData] = useState<IGymSettingData | undefined>(undefined);
  const [selectedArea, setSelectedArea] = useState<IArea | undefined>(undefined);
  const [selectedWall, setSelectedWall] = useState<IWall | undefined>(undefined);
  const [selectedMarker, setSelectedMarker] = useState<IMarker | undefined>(undefined);

  const [isGymScaleModalOpen, setIsGymScaleModalOpen] = useState<boolean>(false);
  const [isNewAreaModalOpen, setIsNewAreaModalOpen] = useState<boolean>(false);
  const [isNewWallModalOpen, setIsNewWallModalOpen] = useState<boolean>(false);
  const [isNewMarkerModalOpen, setIsNewMarkerModalOpen] = useState<boolean>(false);
  const [isReorderAreasModalOpen, setIsReorderAreasModalOpen] = useState<boolean>(false);

  const handleGymSearch = () => {
    setAreas(undefined);
    setSelectedArea(undefined);
    setSelectedGym(undefined);
    setSelectedWall(undefined);
    setGymSettingData(undefined);
    if (gymSearch === '') {
      gymRef
        .orderBy('primaryPhoneNumber', 'desc')
        .limit(5)
        .get()
        .then(gyms => setGyms(gyms.docs))
    } else {
      let newGymSearch = ''
      gymSearch.split(' ').forEach((word, i) => {
        if (i !== 0) {
          newGymSearch += ' ';
        }
        newGymSearch += word.charAt(0).toUpperCase() + word.slice(1);
      });
      gymRef
        .where('name', '>=', newGymSearch)
        .where('name', '<=', newGymSearch + '\uf8ff')
        .limit(5)
        .get()
        .then(gymDocs => setGyms(gymDocs.docs));
    }
  }

  useEffect(() => {
    gymRef
      .orderBy('primaryPhoneNumber', 'desc')
      .limit(5)
      .get()
      .then(gyms => setGyms(gyms.docs))
  }, []);

  useEffect(() => {
    if (selectedGym) {
      areaRef
        .where("gymId", "==", selectedGym.id)
        .get()
        .then(areaDocs => setAreas(
          areaDocs.docs.map(areaDoc => ({
            id: areaDoc.id,
            ...areaDoc.data(),
          }))
        ));

      gymRef
        .doc(selectedGym.id)
        .collection('climbSettingData')
        .doc(selectedGym.id)
        .get()
        .then(climbSettingDataDoc => setGymSettingData(
          climbSettingDataDoc.data() as IGymSettingData
        ));
    }
  }, [selectedGym]);

  const handleChangeGymSearch = (e: any) => setGymSearch(e.target.value);

  const handleToggleGymScaleModal = () => setIsGymScaleModalOpen(!isGymScaleModalOpen);
  const handleToggleAreaModalOpen = () => setIsNewAreaModalOpen(!isNewAreaModalOpen);
  const handleToggleWallModalOpen = () => setIsNewWallModalOpen(!isNewWallModalOpen);
  const handleToggleMarkerModalOpen = () => setIsNewMarkerModalOpen(!isNewMarkerModalOpen);
  const handleToggleReorderAreaModalOpen = () => setIsReorderAreasModalOpen(!isReorderAreasModalOpen);

  const handleSelectGymId = (event: any) => {
    const newGym = gyms.find((gym: any) => gym.id === event.target.value);
    if (newGym) {
      setSelectedWall(undefined);
      setSelectedArea(undefined);
      setAreas(undefined);

      setSelectedGym({
        id: newGym.id,
        ...newGym.data()
      });
    }
  }

  const handleSelectArea = (event: any) => {
    const newArea = areas.find((area: any) => area.id === event.target.value);
    if (newArea) {
      setSelectedWall(undefined);
      setSelectedArea(newArea);
    }
  }

  const handleSelectWall = (event: any) => {
    if (event.target.value === '') {
      setSelectedWall(undefined);
      return;
    }

    const newWall = selectedArea?.walls.find((wall: any) => wall.id === event.target.value);
    if (newWall) {
      setSelectedWall(newWall);
    }
  }

  const handleSelectMarker = (event: any) => {
    if (event.target.value === '') {
      setSelectedMarker(undefined);
      return;
    }

    const newMarker = selectedArea?.markers.find((marker: any) => marker.id === event.target.value);
    if (newMarker) {
      setSelectedMarker(newMarker);
    }
  }

  const handleSaveArea = async (area: IArea) => {
    if (area.id) {
      const updatedAreaData = {...area};
      delete updatedAreaData.id;

      await areaRef
        .doc(area.id)
        .set(updatedAreaData);
    } else {
      const newArea = await areaRef
        .add(area);

      if (newArea?.id) {
        const metaDataUpdate: {[key: string]: any} = {};
        metaDataUpdate[newArea.id] = {lastReset: new Date()};
        await gymMetadataRef
          .doc(area.gymId)
          .update(metaDataUpdate);

        await gymMetadataRef
          .doc(area.gymId)
          .collection('areaAnalytics')
          .doc(newArea.id)
          .set({
            climbType: area.climbType,
            lastReset: new Date(),
            routeColorDistribution: {},
            routeGradeDistribution: {},
            boulderColorDistribution: {},
            boulderGradeDistribution: {},
          });

        area.id = newArea.id;
        setAreas([area, ...areas]);
      }
    }
    handleToggleAreaModalOpen();
  }

  const handleSaveWall = async (wall: IWall) => {
    if (!selectedArea?.id) { // If we dont have the selected area, return
      if (isNewWallModalOpen) {
        handleToggleWallModalOpen();
      }
      return;
    }

    for (let i = 0; i<wall.paths.length; i++) {
      wall.paths[i].path = removeBadSvgPathVars(wall.paths[i].path);
    }

    if (wall.id !== '') { // If its an existing wall
      const newWalls = selectedArea.walls.map(currentWall => {
        if (wall.id === currentWall.id) {
          return wall;
        } else {
          return currentWall;
        }
      });
      selectedArea.walls = newWalls;

      await areaRef
        .doc(selectedArea.id)
        .set(selectedArea);
      
      setSelectedWall(wall);
    } else {
      wall.id = 'wa_' + cuid();
      selectedArea.walls.push(wall);
      const batch = db.batch();
      batch.update(
        areaRef
          .doc(selectedArea.id),
        {
          walls: selectedArea.walls,
        },
      );
      const gymMetadataUpdate: any = {}
      gymMetadataUpdate[`${selectedArea.id}.${wall.id}`] = 0;
      batch.update(
        gymMetadataRef
          .doc(selectedGym?.id),
        gymMetadataUpdate,
      );
      batch.set(
        areaRef
          .doc(selectedArea.id)
          .collection('campaigns')
          .doc(),
        {
          activatedAt: new Date(),
          archivedAt: null,
          deletedAt: null,
          createdAt: new Date(),
          status: 1,
          climbType: selectedArea.climbType,
          boulderColorDistribution: {},
          routeColorDistribution: {},
          boulderGradeDistribution: {},
          routeGradeDistribution: {},
          gymId: selectedArea.gymId,
          areaId: selectedArea.id,
          wallId: wall.id,
          name: '',
        }
      );

      try {
        await batch.commit();
      } catch (err) {
        console.log(err);
        window.alert('Failed to save wall.')
      }
    }

    if (isNewWallModalOpen) {
      handleToggleWallModalOpen();
    }
  }

  const handleSaveMarker = async (marker: IMarker) => {
    if (!selectedArea?.id) { // If we dont have the selected area, return
      if (isNewMarkerModalOpen) {
        handleToggleMarkerModalOpen();
      }
      return;
    }

    if (marker.id !== '') { // If its an existing marker
      const newMarkers = selectedArea.markers.map(currentMarker => {
        if (marker.id === currentMarker.id) {
          return marker;
        } else {
          return currentMarker;
        }
      });
      selectedArea.markers = newMarkers;

      await areaRef
        .doc(selectedArea.id)
        .update({markers: newMarkers});

      setSelectedMarker(marker);
    } else {
      marker.id = 'ma_' + cuid();
      selectedArea.markers.push(marker);
      await areaRef
        .doc(selectedArea.id)
        .update({
          markers: selectedArea.markers,
        });
    }

    if (isNewMarkerModalOpen) {
      handleToggleMarkerModalOpen();
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100vh'}}>
      <Header />

      <div style={{display: 'flex', flex: 1}}>
        {isGymScaleModalOpen && (
          <GymScaleFormModal
            gymId={selectedGym?.id}
            gymSettingData={gymSettingData}
            handleCloseModal={handleToggleGymScaleModal}
            setGymSettingData={setGymSettingData}
          />
        )}
        {(isNewAreaModalOpen && selectedGym && areas) && (
          <AreaFormModal
            gymId={selectedGym.id}
            numAreas={areas.length}
            handleSaveArea={handleSaveArea}
            handleCloseModal={handleToggleAreaModalOpen}
          />
        )}
        {(isNewWallModalOpen && selectedArea) &&
          <div style={{zIndex: 1, position: 'absolute', top: 0, minHeight: '120vh', width: '100vw', backgroundColor: Colors.MODALBACKGROUND}}>
            <div style={{ backgroundColor: Colors.WHITE, borderRadius: 25, margin: 40, padding: 40}}>
              <h2 style={{textAlign: 'center', margin: 0}}>Add a Wall</h2>
              <WallForm handleSaveWall={handleSaveWall} handleCloseModal={handleToggleWallModalOpen} areaColor={selectedArea.color} method='create' wall={undefined} />
            </div>
          </div>
        }
        {isNewMarkerModalOpen &&
          <div style={{zIndex: 1, position: 'absolute', top: 0, height: '120vh', width: '100vw', backgroundColor: Colors.MODALBACKGROUND}}>
            <div style={{ backgroundColor: Colors.WHITE, borderRadius: 25, margin: 40, padding: 40}}>
              <h2 style={{textAlign: 'center', margin: 0}}>Add a Marker</h2>
              <MarkerForm handleSaveMarker={handleSaveMarker} handleCloseModal={handleToggleMarkerModalOpen} method='create' marker={undefined} />
            </div>
          </div>
        }
        {(isReorderAreasModalOpen && selectedGym && areas) && 
          <ReorderAreasModal
            gymId={selectedGym.id}
            areas={areas}
            handleCloseModal={handleToggleReorderAreaModalOpen}
          />
        }

        <div style={{margin: 15, display: 'flex', flexDirection: 'column', flex: 1}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h3 style={{marginBottom: 8}}>Gym:</h3>
            <div>
              <input type="text" id="gsearch" placeholder='Gym Search' value={gymSearch} onChange={handleChangeGymSearch} />
              <button onClick={handleGymSearch}>Search</button>
            </div>
            {!gyms ? <p>Loading...</p> :
              <select value={selectedGym?.id} onChange={handleSelectGymId} style={{width: '20vw', height: '50px'}}  >
                {selectedGym === undefined && <option value={undefined}>Select a gym</option>}
                {gyms.map((gym: any) => 
                  <option key={gym.id} value={gym.id}>{gym.data().name}</option>
                )}
              </select>
            }
            {selectedGym !== undefined && (
              <button
                onClick={handleToggleGymScaleModal} 
                style={{alignSelf: 'flex-start', marginTop: 5}}
              >
                Edit Climb Setting Data
              </button>
            )}
          </div>

          <div>
            <h3 style={{marginBottom: 8}}>Areas:</h3>
            {(selectedGym && areas) ? 
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <select value={selectedArea?.id} onChange={handleSelectArea} style={{width: '20vw', height: '50px'}}>
                  {selectedArea === undefined && <option value={undefined}>Select an area</option>}
                  {areas.sort((a: any, b: any) => a.order - b.order).map((area: any) =>
                    <option key={area.id} value={area.id}>{area.name}</option>
                  )}
                </select>
                <div style={{alignSelf: 'flex-start', marginTop: 5}}>
                  <button
                    onClick={handleToggleAreaModalOpen} 
                    style={{marginRight: 5}}
                  >
                    + Add Area
                  </button>
                  <button
                    onClick={handleToggleReorderAreaModalOpen} 
                  >
                    Reorder
                  </button>
                </div>
              </div>
              :
              <p>Please select a gym</p>
            }
          </div>

          <div>
            <h3 style={{marginBottom: 8}}>Markers:</h3>
            {selectedArea ?
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <select value={selectedMarker?.id || ''} onChange={handleSelectMarker} style={{width: '20vw', height: '50px'}}>
                  <option value=''>Select a marker</option>
                  {selectedArea.markers.map((marker: any) =>
                    <option key={marker.id} value={marker.id}>{marker.name}</option>
                  )}
                </select>
                <button
                  onClick={handleToggleMarkerModalOpen} 
                  style={{alignSelf: 'flex-start', marginTop: 5}}
                >
                  + Add Marker
                </button>
              </div>
              :
              <p>Please select an area</p>
            }
          </div>
          {selectedMarker && <MarkerForm handleSaveMarker={handleSaveMarker} handleCloseModal={undefined} key={selectedMarker.id} method='edit' marker={selectedMarker} />}

          <div>
            <h3 style={{marginBottom: 8}}>Walls:</h3>
            {selectedArea ?
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <select value={selectedWall?.id || ''} onChange={handleSelectWall} style={{width: '20vw', height: '50px'}}>
                  <option value=''>Select a wall</option>
                  {selectedArea.walls.map((wall: any) =>
                    <option key={wall.id} value={wall.id}>{wall.name}</option>
                  )}
                </select>
                <button
                  onClick={handleToggleWallModalOpen} 
                  style={{alignSelf: 'flex-start', marginTop: 5}}
                >
                  + Add Wall
                </button>
              </div>
              :
              <p>Please select an area</p>
            }
          </div>
          {(selectedWall && selectedArea) && <WallForm handleSaveWall={handleSaveWall} handleCloseModal={undefined} areaColor={selectedArea.color} key={selectedWall.id} method='edit' wall={selectedWall} />}
        </div>

        <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center'}}>
          <p style={{fontWeight: 'bold', fontSize: 24, marginTop: 25}}>Area Map</p>
          {(selectedArea) ? <AreaMap area={selectedArea} /> : <p>Select an area to see the map</p>}

          <p style={{fontWeight: 'bold', fontSize: 24, marginBottom: 5, marginTop: 100, textAlign: 'center'}}>Wall View</p>
          {(selectedArea && selectedWall) ? <WallMap wall={selectedWall} area={selectedArea} /> : <p>Select an wall to see the view</p>}          
        </div>
      </div>

      {/* <button onClick={handleBulkAction}>
        Handle send email
      </button> */}

    </div>
  );
}

export default Dashboard;
