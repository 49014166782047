import React from 'react';
import { Formik, Field } from 'formik';

import { Colors } from '../../constants';
import { IArea } from '../../interfaces';
import { areaSchema } from '../../validationSchema';
import { ClimbTypes } from '../../interfaces/ClimbTypes';

const AreaFormModal = ({
    gymId,
    numAreas,
    handleCloseModal,
    handleSaveArea,
} : {
    gymId: string | undefined;
    numAreas: number;
    handleCloseModal: () => void;
    handleSaveArea: (area: IArea) => void;
}) => {
    const initialAreaValues = {
        gymId: gymId,
        name: '',
        color: '',
        climbType: ClimbTypes.Route,
        lastReset: new Date(),
        walls: [],
        markers: [],
        order: numAreas,
    } as IArea;

    return (
        <div style={{zIndex: 1, position: 'absolute', top: 0, height: '120vh', width: '100vw', backgroundColor: Colors.MODALBACKGROUND}}>
            <div style={{backgroundColor: Colors.WHITE, borderRadius: 25, margin: 40, padding: 40}}>
                <h2 style={{textAlign: 'center', margin: 0}}>Add an Area</h2>
                <Formik
                    initialValues={initialAreaValues}
                    validationSchema={areaSchema}
                    onSubmit={handleSaveArea}
                >
                    {({ values, submitForm }: {values: IArea, submitForm: () => void}) => {

                        return(
                            <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                                <label style={{marginTop: 20}} htmlFor="name">Name</label>
                                <Field id="name" name="name" placeholder="Area Name" style={{height: 25}} />

                                <label style={{marginTop: 10}} htmlFor="color">Color</label>
                                <Field id="color" name="color" placeholder="Color" style={{height: 25}} />

                                <label style={{marginTop: 10}} htmlFor="climbType">Area Climb Type</label>
                                <Field as="select" name="climbType" style={{height: 25}} >
                                    <option value={ClimbTypes.Route}>Route</option>
                                    <option value={ClimbTypes.Boulder}>Boulder</option>
                                    <option value={ClimbTypes.Mixed}>Mixed</option>
                                </Field>

                                <div style={{alignSelf: 'center', marginTop: 15}}>
                                    <button
                                        type="button"
                                        onClick={handleCloseModal}
                                        style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, marginRight: 15, padding: 5}}
                                    >
                                        Close
                                    </button>
                                    <button
                                        onClick={submitForm}
                                        type="submit"
                                        style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, padding: 5}}
                                    >
                                        Save
                                    </button>
                                </div>
                                
                            </div>
                        )
                    }}
                </Formik>
            </div>
        </div>
    );
}

export default AreaFormModal;
