import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';

const Header = () => {
  const { logout, currentUser } = useAuth();
  const history = useHistory();

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch(err) {
      window.alert('Error signing out!')
    }
  }

  const handlePostNews = () => window.location.href = '/post-news';

  return (
    <Navbar bg="dark" variant="dark" className='justify-content-between'>
        <Navbar.Brand>Climb Time Admin</Navbar.Brand>
        <button style={{marginLeft: 50}} onClick={handlePostNews}>
          Post News
        </button>
        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
                Signed in as: {currentUser.email}
            </Navbar.Text>
            <Button onClick={handleLogout} variant="link">Log Out</Button>
        </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
